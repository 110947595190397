<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/设备管理/[新建]'
          }"
          @click="headleAddEquip"
        >
          新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/设备管理/[导入]'
          }"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/设备管理/[导出]'
          }"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="headleEditEquip(row)">
          编辑
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <edit-dialog ref="childEdit" @headleAddSuccess="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';

import EditDialog from './components/editDialog.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { getWarehouseAssetsPage } from './api';

export default {
  name: 'StorageEquipment',
  components: {
    EditDialog,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      formData: {},
      loading: { getTableData: false },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getWarehouseAssetsPage(pagination, this.formData);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    headleAddEquip() {
      this.$refs.childEdit.init();
    },
    headleEditEquip(row) {
      this.$refs.childEdit.init(row);
    },
    filterLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
