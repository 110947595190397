<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      :track="{
        name_zh: `基础数据/仓库相关/设备管理/弹窗-${
          edit? '编辑': '新建'
        }`,
        trackName: $route.path
      }"
      custom-class="custom-dialog_50"
      :title="edit? '编辑':'新建'"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备编码" prop="assetsCode">
              <el-input v-model="form.assetsCode" :disabled="edit" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备类型" prop="assetsType">
              <el-select v-model="form.assetsType" placeholder="请选择">
                <el-option
                  v-for="item in ASSETS_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备属性" prop="ownershipType">
              <el-select v-model="form.ownershipType" placeholder="请选择">
                <el-option
                  v-for="item in OWNER_SHIP_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属方"
              prop="ownershipName"
              :rules="[
                { required: DICTIONARY.lease === form.ownershipType, message: '请输入所属方'},
              ]"
            >
              <el-input v-model="form.ownershipName" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备状态" prop="assetsStatus">
              <el-select v-model="form.assetsStatus" placeholder="请选择">
                <el-option
                  v-for="item in ASSET_STATUW"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <nh-button
            type="primary"
            :loading="loading.headleSubmit"
            @click="headleSubmit"
          >
            确 定
          </nh-button>
          <nh-button @click="headlClose">
            取 消
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  DICTIONARY, ASSETS_TYPE, ASSET_STATUW, OWNER_SHIP_TYPE,
} from '../fileds';
import { getWarehouseAssetsInsert, getWarehouseAssetsUpdate, getWarehouseAssetsGet } from '../api';

export default {
  name: 'EditDialog',
  mixins: [loadingMixin],
  emits: ['headleAddSuccess'],
  data() {
    return {
      DICTIONARY,
      ASSETS_TYPE,
      ASSET_STATUW,
      OWNER_SHIP_TYPE,
      edit: false,
      dialogVisible: false,
      loading: {
        headleSubmit: false,
      },
      form: {},
      rules: {
        assetsCode: [
          { required: true, message: '请输入设备编码', trigger: 'blur' },
        ],
        assetsType: [
          { required: true, message: '请选择设备类型', trigger: 'change' },
        ],
        ownershipType: [
          { required: true, message: '请选择设备属性', trigger: 'change' },
        ],
        assetsStatus: [
          { required: true, message: '请选择设备状态', trigger: 'change' },
        ],
      },
      selectList: {
        ownershipName: [],
      },
    };
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.form = {};
      if (data) {
        this.getWarehouseAssets(data.id);
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    async getWarehouseAssets(id) {
      const resp = await getWarehouseAssetsGet({ id });
      this.form = resp;
    },
    async headleSubmit() {
      await this.$refs.ruleForm.validate();
      const data = { ...this.form };
      if (!this.edit) {
        await getWarehouseAssetsInsert(data);
      } else {
        await getWarehouseAssetsUpdate(data);
      }
      this.$message({
        type: 'success',
        message: `${this.edit ? '编辑' : '新增'}成功!`,
      });
      this.$emit('headleAddSuccess');
      this.headlClose();
    },
    headlClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },

  },
};
</script>
