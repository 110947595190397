import { FormType } from '@/constant/form';

const DICTIONARY = {
  lease: 'lease',
};
const ASSETS_TYPE = [{
  label: '周转箱',
  value: 'container',
}, {
  label: '托盘',
  value: 'stock',
}, {
  label: '复核台',
  value: 'ribozyme ',
}];
const ASSET_STATUW = [{
  label: '正常',
  value: 'normal',
}, {
  label: '维修中',
  value: 'maintenance',
}, {
  label: '已退还',
  value: 'returned',
}, {
  label: '已报废',
  value: 'scrap',
}];
const OWNER_SHIP_TYPE = [{
  label: '自有',
  value: 'own',
}, {
  label: '租赁',
  value: 'lease',
}];

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '设备编码',
  prop: 'assetsCode',
}, {
  label: '设备类型',
  prop: 'assetsType',
  formatter: (row) => filterLabel(row.assetsType, ASSETS_TYPE),
}, {
  label: '设备属性',
  prop: 'ownershipType',
  formatter: (row) => filterLabel(row.ownershipType, OWNER_SHIP_TYPE),

}, {
  label: '状态',
  prop: 'assetsStatus',
  formatter: (row) => filterLabel(row.assetsStatus, ASSET_STATUW),
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
}];

export {
  DICTIONARY, ASSETS_TYPE, ASSET_STATUW, OWNER_SHIP_TYPE, TABLECOLUMN,
};
export const serchFields = [
  {
    label: '设备编码',
    prop: 'assetsCode',
    component: FormType.INPUT,
  }, {
    label: '设备类型',
    prop: 'assetsType',
    component: FormType.SELECT,
    options: ASSETS_TYPE,
  }, {
    label: '状态',
    prop: 'assetsStatus',
    component: FormType.SELECT,
    options: ASSET_STATUW,
  },
];
