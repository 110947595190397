import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 设备(资产) 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11238
 */

export function getWarehouseAssetsPage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/assets/page', data, {
    params,
  });
}
/**
 * @description: 设备(资产) 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11234
 */
export function getWarehouseAssetsGet(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/assets/get?id=${id}`);
}
/**
 * @description: 设备(资产) 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11236
 */
export function getWarehouseAssetsDelete(params) {
  return wmsPlusHttp.get('/warehouse_foundation/assets/delete', params);
}

/**
 * @description: 设备(资产) 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11230
 */
export function getWarehouseAssetsInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/assets/insert', data);
}
/**
 * @description: 设备(资产) 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11232
 */
export function getWarehouseAssetsUpdate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/assets/update', data);
}
